import { Navigation } from 'components';
import { PhoneNumber } from 'components/phone-number';

import styles from './home-page.module.scss';

export default function HomePage() {
  return (
    <div className={styles.page}>
      <Navigation />
      <PhoneNumber />
    </div>
  )
}
