import { Link } from 'react-router-dom';

import styles from './navigation.module.scss';

export default function Navigation() {
  return (
    <nav>
      <ul className={styles.items}>
        <li className={styles.item}>
          <Link to="/search">Поиск услуг</Link>
        </li>

        <li className={styles.item}>
          <Link to="/selected">Выбранные услуги</Link>
        </li>
      </ul>
    </nav>
  )
}
