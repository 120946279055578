import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingIcon } from 'components/icons';

import api from 'services/api-service';
import { getServicesById } from 'store/slices/searchSlice';
import { getTelegramUserId } from 'helpers/telegram-helper';
import { getConsultationFormId, getConsultationFormUnitTag, getPrivacyPolicyLink } from 'helpers/forms-helper';

import styles from './service-page.module.scss';

export default function ServicePage() {
  const [formSentStatus, setFormSentStatus] = useState('');

  const { register, formState: { errors, isSubmitSuccessful, isSubmitting }, handleSubmit } = useForm();

  const { serviceId } = useParams();

  const { serviceInfo, serviceLoading, serviceLoadingError } = useSelector(state => state.search);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    formData.append('_wpcf7_unit_tag', 'wpcf7-' + getConsultationFormUnitTag());

    const response = await api.sendServiceForm({ formId: getConsultationFormId(), formData });

    setFormSentStatus(response.data.status);
  };

  useEffect(() => {
    async function sendMessageToChat() {
      if (formSentStatus === 'mail_sent' && isSubmitSuccessful) {
        await api.sendMessageToUserChat(getTelegramUserId(), 'Ваше сообщение успешно отправлено. Наши специалисты свяжутся с вами в ближайшее время.');
        window.Telegram.WebApp.close();
      }
    }

    sendMessageToChat();
  }, [formSentStatus, isSubmitSuccessful]);

  useEffect(() => {
    dispatch(getServicesById(serviceId));
  }, [dispatch, serviceId]);

  return (
      serviceLoading
      ? (
          <div className={styles.page}>
            <div className={styles.loadingWrapper}>
              <div>Загрузка контактной формы</div>
              <div>
                <LoadingIcon />
              </div>
            </div>
          </div>
        )
      : serviceLoadingError
        ? (
            <div className={styles.page}>
              <div className={styles.error}>{serviceLoadingError}</div>
            </div>
          )
        : (
          <div className={styles.page} onSubmit={handleSubmit(onSubmit)}>
            <h1 className={styles.title}>Консультация по услуге</h1>
            {serviceInfo && <div className={styles.serviceName}>{serviceInfo.title}</div>}

            <form className={styles.form}>
              {serviceInfo && <input type='hidden' {...register('serviceName', { value: serviceInfo.title })} />}
              {serviceInfo && <input type='hidden' {...register('serviceCode', { value: serviceInfo.code })} />}
              <label className={styles.formControl}>
                <span>Ваше имя</span>

                <input type='text' className={styles.formControlField} {...register('yourName', {
                  required: 'Необходимо заполнить это поле',
                })} />

                {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
              </label>

              <label className={styles.formControl}>
                <span>Ваш телефон</span>

                <input type='phone' className={styles.formControlField} {...register('yourPhone', {
                  required: 'Необходимо заполнить это поле',
                })} />

                {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
              </label>

              <label className={styles.formControl}>
                <span>Ваше сообщение</span>

                <textarea className={`${styles.formControlField} ${styles.formControlTextarea}`} {...register('yourMessage', {
                  required: 'Необходимо заполнить это поле',
                })}></textarea>

                {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
              </label>

              <div className={styles.formControl}>
                <button type='submit' className={styles.formSubmitButton} disabled={isSubmitting}>
                  {isSubmitting  ? 'Отправляется...' : 'Отправить'}
                </button>
              </div>

              <footer className={styles.formFooter}>
                <div>Вводя свои данные, вы соглашаетесь с <a href={getPrivacyPolicyLink()} target='_blank' rel="noreferrer">политикой конфиденциальности</a></div>
              </footer>
            </form>
          </div>
      )
  )
}
