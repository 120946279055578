import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from 'services/api-service';

const initialState = {
  text: '',
  loading: false,
  selectedItemsFetchingLoading: false,
  error: null,
  selectedItemsFetchingError: null,
  foundItems: [],
  selectedItems: [],
  sendServiceError: null,
  serviceSent: false,
  serviceLoading: false,
  serviceLoadingError: null,
  serviceInfo: null,
};

export const getServicesByName = createAsyncThunk(
  '@@search/getServicesByName',
  async (serviceName, { getState, rejectWithValue }) => {
    try {
      const response = await api.findServicesByName(serviceName);

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const getServicesById = createAsyncThunk(
  '@@search/getServicesByIda',
  async (serviceId, { getState, rejectWithValue }) => {
    try {
      const response = await api.getServicesById(serviceId);

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const addServiceToSelected = createAsyncThunk(
  '@@search/addServiceToSelected',
  async (serviceId, { getState, rejectWithValue }) => {
    try {
      const data = await api.addServiceToSelected(serviceId);

      return data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const removeServiceFromSelected = createAsyncThunk(
  '@@search/removeServiceFromSelected',
  async (serviceId, { getState, rejectWithValue }) => {
    try {
      const data = await api.removeServiceToSelected(serviceId);

      return data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const removeServiceFromSelectedList = createAsyncThunk(
  '@@search/removeServiceFromSelectedList',
  async (serviceId, { getState, rejectWithValue }) => {
    try {
      const data = await api.removeServiceToSelected(serviceId);

      return data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const getSelectedServices = createAsyncThunk(
  '@@search/getSelectedServices',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await api.getSelectedServices();

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return rejectWithValue(errorHandler(error, getState()))
      }
      return rejectWithValue(error.message);
    }
  },
);

export const sendServiceToClientChat = createAsyncThunk(
  '@@search/sendServiceToClientChat',
  async (serviceId, { rejectWithValue }) => {
    try {
      const response = await api.sendServiceToUserChat(serviceId);

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          return rejectWithValue('Неудалость найти информацию лб услуге');
        }

        if (error.response?.status >= 500) {
          return rejectWithValue('Неудалось найти услуги. Ошибка сервера');
        }

        return rejectWithValue(error.response?.data?.data);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const sendServiceFromSelectedToClientChat = createAsyncThunk(
  '@@search/sendServiceFromSelectedToClientChat',
  async (serviceId, { rejectWithValue }) => {
    try {
      const response = await api.sendServiceToUserChat(serviceId);

      return response.data.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          return rejectWithValue('Неудалость найти информацию лб услуге');
        }

        if (error.response?.status >= 500) {
          return rejectWithValue('Неудалось найти услуги. Ошибка сервера');
        }

        return rejectWithValue(error.response?.data?.data);
      }
      return rejectWithValue(error.message);
    }
  },
);

function errorHandler(error) {
  if (error.response?.status === 404) {
    return 'Неудалость найти услуги по вашему запрос';
  }

  if (error.response?.status >= 500) {
    return 'Неудалось найти услуги. Ошибка сервера';
  }

  return error.response?.data?.data;
}

export const searchSlice = createSlice({
  name: '@@search',
  initialState,
  reducers: {
    updateText: (state, { payload }) => {
      state.text = payload;

      if (payload === '') {
        state.error = null;
        state.foundItems = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServicesByName.pending, (state) => {
      state.error = null;
      state.loading = true;
    });

    builder.addCase(getServicesByName.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.foundItems = action.payload;
    });

    builder.addCase(getServicesByName.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.foundItems = [];
    });

    builder.addCase(getServicesById.pending, (state) => {
      state.serviceLoadingError = null;
      state.serviceLoading = true;
    });

    builder.addCase(getServicesById.fulfilled, (state, action) => {
      state.serviceLoadingError = null;
      state.serviceLoading = false;
      state.serviceInfo = action.payload;
    });

    builder.addCase(getServicesById.rejected, (state, action) => {
      state.serviceLoadingError = action.payload;
      state.serviceLoading = false;
      state.serviceInfo = null;
    });

    builder.addCase(addServiceToSelected.pending, (state, action) => {
      state.error = null;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: true,
          }
        }

        return item;
      });
    });

    builder.addCase(addServiceToSelected.fulfilled, (state, action) => {
      state.error = null;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            selected: true,
            loading: false,
          }
        }

        return item;
      });
      state.selectedItems.push(state.foundItems.find(({id}) => id === action.meta.arg));
    });

    builder.addCase(addServiceToSelected.rejected, (state, action) => {
      state.error = action.payload;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: false,
          }
        }

        return item;
      });
    });

    builder.addCase(removeServiceFromSelected.pending, (state, action) => {
      state.error = null;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: true,
          }
        }

        return item;
      });
    });

    builder.addCase(removeServiceFromSelected.fulfilled, (state, action) => {
      state.error = null;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            selected: false,
            loading: false,
          }
        }

        return item;
      });
      state.selectedItems = state.selectedItems.filter(({id}) => id !== action.meta.arg);
    });

    builder.addCase(removeServiceFromSelected.rejected, (state, action) => {
      state.error = action.payload;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: false,
          }
        }

        return item;
      });
    });

    builder.addCase(getSelectedServices.pending, (state) => {
      state.selectedItemsFetchingError = null;
      state.selectedItemsFetchingLoading = true;
    });

    builder.addCase(getSelectedServices.fulfilled, (state, action) => {
      state.selectedItemsFetchingError = null;
      state.selectedItemsFetchingLoading = false;
      state.selectedItems = action.payload;
    });

    builder.addCase(getSelectedServices.rejected, (state, action) => {
      state.selectedItemsFetchingError = action.payload;
      state.selectedItemsFetchingLoading = false;
      state.selectedItems = [];
    });

    builder.addCase(removeServiceFromSelectedList.pending, (state, action) => {
      state.selectedItemsFetchingError = null;
      state.selectedItems = state.selectedItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: true,
          }
        }

        return item;
      });
    });

    builder.addCase(removeServiceFromSelectedList.fulfilled, (state, action) => {
      state.selectedItemsFetchingError = null;
      state.selectedItems = state.selectedItems.filter(({id}) => id !== action.meta.arg);
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            selected: false,
          }
        }

        return item;
      });
    });

    builder.addCase(removeServiceFromSelectedList.rejected, (state, action) => {
      state.selectedItemsFetchingError = action.payload;
      state.selectedItems = state.selectedItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            loading: false,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceToClientChat.pending, (state, action) => {
      state.sendServiceError = null;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          console.log('aaaa');
          return {
            ...item,
            sending: true,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceToClientChat.fulfilled, (state, action) => {
      state.sendServiceError = null;
      state.serviceSent = true;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            sending: false,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceToClientChat.rejected, (state, action) => {
      state.sendServiceError = action.payload;
      state.foundItems = state.foundItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            sending: false,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceFromSelectedToClientChat.pending, (state, action) => {
      state.sendServiceError = null;
      state.selectedItems = state.selectedItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            sending: true,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceFromSelectedToClientChat.fulfilled, (state, action) => {
      state.sendServiceError = null;
      state.serviceSent = true;
      state.selectedItems = state.selectedItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            sending: false,
          }
        }

        return item;
      });
    });

    builder.addCase(sendServiceFromSelectedToClientChat.rejected, (state, action) => {
      state.sendServiceError = action.payload;
      state.selectedItems = state.selectedItems.map(item => {
        if (item.id === action.meta.arg) {
          return {
            ...item,
            sending: false,
          }
        }

        return item;
      });
    });
  },
});

export const { updateText } = searchSlice.actions;

export default searchSlice.reducer;
