import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingIcon } from 'components/icons';

import api from 'services/api-service';
import { getTelegramUserId } from 'helpers/telegram-helper';
import { getPrivacyPolicyLink, getSelectedServicesFormId, getSelectedServicesFormUnitTag } from 'helpers/forms-helper';
import { getSelectedServices } from 'store/slices/searchSlice';

import styles from './selected-consultation.module.scss';

export default function SelectedConsultationPage() {
  const [formSentStatus, setFormSentStatus] = useState('');

  const { register, formState: { errors, isSubmitSuccessful, isSubmitting }, handleSubmit } = useForm();

  const { selectedItems, selectedItemsFetchingLoading, selectedItemsFetchingError } = useSelector(state => state.search);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    formData.append('_wpcf7_unit_tag', getSelectedServicesFormUnitTag());

    const response = await api.sendForm(getSelectedServicesFormId(), formData);

    setFormSentStatus(response.data.status);
  };

  useEffect(() => {
    async function sendMessageToChat() {
      if (formSentStatus === 'mail_sent' && isSubmitSuccessful) {
        await api.sendMessageToUserChat(getTelegramUserId(), 'Ваше сообщение успешно отправлено. Наши специалисты свяжутся с вами в ближайшее время.');
        window.Telegram.WebApp.close();
      }
    }

    sendMessageToChat();
  }, [formSentStatus, isSubmitSuccessful]);

  useEffect(() => {
    dispatch(getSelectedServices());
  }, [dispatch]);

  useEffect(() => {
    if (selectedItems.length) {
      console.log(selectedItems);
    }
  }, [selectedItems]);

  return (
      selectedItemsFetchingLoading
      ? (
          <div className={styles.page}>
            <div className={styles.loadingWrapper}>
              <div>Загрузка контактной формы</div>
              <div>
                <LoadingIcon />
              </div>
            </div>
          </div>
        )
      : selectedItemsFetchingError
        ? (
            <div className={styles.page}>
              <div className={styles.error}>{selectedItemsFetchingError}</div>
            </div>
          )
        : !selectedItems.length
          ? (
              <div className={styles.page}>
                <div>У вас нет выбранных услуг</div>
                <Link to='/'>На главную</Link>
              </div>
            )
          : (
              <div className={styles.page} onSubmit={handleSubmit(onSubmit)}>
                <h1 className={styles.title}>Получить консультацию по выбранным услугам</h1>

                <form className={styles.form}>
                  <input className={styles.hidden} checked type='text' {...register('selectedItems')} value={selectedItems.map((item) => `${item.code} - ${item.title}\n`)} />

                  <label className={styles.formControl}>
                    <span>Ваше имя</span>

                    <input type='text' className={styles.formControlField} {...register('yourName', {
                      required: 'Необходимо заполнить это поле',
                    })} />

                    {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
                  </label>

                  <label className={styles.formControl}>
                    <span>Ваш телефон</span>

                    <input type='phone' className={styles.formControlField} {...register('yourPhone', {
                      required: 'Необходимо заполнить это поле',
                    })} />

                    {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
                  </label>

                  <label className={styles.formControl}>
                    <span>Ваше сообщение</span>

                    <textarea className={`${styles.formControlField} ${styles.formControlTextarea}`} {...register('yourMessage', {
                      required: 'Необходимо заполнить это поле',
                    })}></textarea>

                    {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
                  </label>

                  <div className={styles.formControl}>
                    <button type='submit' className={styles.formSubmitButton} disabled={isSubmitting}>
                      {isSubmitting  ? 'Отправляется...' : 'Отправить'}
                    </button>
                  </div>

                  <footer className={styles.formFooter}>
                    <div>Вводя свои данные, вы соглашаетесь с <a href={getPrivacyPolicyLink()} target='_blank' rel="noreferrer">политикой конфиденциальности</a></div>
                  </footer>
                </form>
              </div>
            )
  );
}
