import PropTypes from 'prop-types';

import styles from './loading-icon.module.scss';

export default function LoadingIcon({
  size,
  className,
  color,
}) {
  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={size}
        height={size}
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
        className={styles.icon}
      >
        <circle
          cx='50'
          cy='50'
          fill='none'
          stroke={color}
          strokeWidth='6'
          r='19'
          strokeDasharray='89.5353906273091 31.845130209103033'
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            repeatCount='indefinite'
            dur='1s'
            values='0 50 50;360 50 50'
            keyTimes='0;1'
          />
        </circle>
      </svg>
    </div>
  )
}

LoadingIcon.defaultProps = {
  size: 40,
  className: '',
  color: 'var(--tg-theme-button-color)',
};

LoadingIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
};
