import { getPhoneNumber } from 'helpers/phone-number-helper';
import styles from './phone-number.module.scss';

export default function PhoneNumber() {
  const phoneNumber = getPhoneNumber();

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>Для справок звоните по номеру:</p>
      <p className={styles.number}>{phoneNumber}</p>
    </div>
  );
}
