import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { BackButton } from 'components';

import api from 'services/api-service';

import styles from './feedback-page.module.scss';
import { getTelegramUserId } from 'helpers/telegram-helper';
import { getFeedbackFormId, getFeedbackFormUnitTag, getPrivacyPolicyLink } from 'helpers/forms-helper';

export default function FeedbackPage() {
  const [formSentStatus, setFormSentStatus] = useState('');

  const { register, formState: { errors, isSubmitSuccessful, isSubmitting }, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    formData.append('_wpcf7_unit_tag', 'wpcf7-' + getFeedbackFormUnitTag());

    const response = await api.sendForm(getFeedbackFormId(), formData);

    setFormSentStatus(response.data.status);
  };

  useEffect(() => {
    async function sendMessageToChat() {
      if (formSentStatus === 'mail_sent' && isSubmitSuccessful) {
        await api.sendMessageToUserChat(getTelegramUserId(), 'Ваше сообщение успешно отправлено. Наши специалисты свяжутся с вами в ближайшее время.');
        window.Telegram.WebApp.close();
      }
    }

    sendMessageToChat();
  }, [formSentStatus, isSubmitSuccessful]);

  return (
    <div className={styles.page} onSubmit={handleSubmit(onSubmit)}>
      <h1 className={styles.title}>Обратная связь</h1>

      <form className={styles.form}>
        <label className={styles.formControl}>
          <span>Ваше имя</span>

          <input type='text' className={styles.formControlField} {...register('yourName', {
            required: 'Необходимо заполнить это поле',
          })} />

          {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
        </label>

        <label className={styles.formControl}>
          <span>Ваш телефон</span>

          <input type='phone' className={styles.formControlField} {...register('yourPhone', {
            required: 'Необходимо заполнить это поле',
          })} />

          {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
        </label>

        <label className={styles.formControl}>
          <span>Ваше собщение</span>

          <textarea className={`${styles.formControlField} ${styles.formControlTextarea}`} {...register('yourMessage', {
            required: 'Необходимо заполнить это поле',
          })}></textarea>

          {errors.yourName && <div className={styles.formControlError}>{errors.yourName.message}</div>}
        </label>

        <div className={styles.formControl}>
          <button type='submit' className={styles.formSubmitButton} disabled={isSubmitting}>
            {isSubmitting  ? 'Отправляется...' : 'Отправить'}
          </button>
        </div>

        <footer className={styles.formFooter}>
          <div>Вводя свои данные, вы соглашаетесь с <a href={getPrivacyPolicyLink()} target='_blank' rel="noreferrer">политикой конфиденциальности</a></div>
        </footer>
      </form>

      <BackButton text='Назад' />
    </div>
  )
}
